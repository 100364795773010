@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --azul-hidrometer: rgb(3, 129, 179);
  --azul-escuro: rgb(3, 84, 119);
  --azul-opaco: rgb(3, 84, 119, 0.9);
  --cor-sucesso: #61bd4f;
  --cor-nao-justificada: #797979;
  --cor-parada: #e0a600;
  --cor-alerta: #ff6b18;
  --cor-comando: #2457c5;
  --cor-erro: #e14747;
  --cor-relatorio: #810d70;
  --cor-de-fundo-barra-de-progresso: #C4E7FE;
  --cor-de-preenchimento-barra-de-progresso: #0095FF;
  --cor-meta-vermelho: #FA5A7D;
  --cor-meta-laranja: #FF947A;
  --cor-meta-verde: #3CD856;
  --cor-meta-roxo: #BF83FF;
  --cor-meta-vermelho-pastel: #FADCD9;
  --cor-meta-laranja-pastel: #FFEEDD;
  --cor-meta-verde-pastel: #D7FFAB;
  --cor-meta-roxo-pastel: #ECD4FF;
  --cinza: #757575;
  --cor-meta-textos: #151D48;
}

* {
  font-family: "Poppins", sans-serif !important;
}

html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 120%;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.mat-tooltip {
  font-size: 12px;
  background-color: var(--azul-opaco);
}

body {
  line-height: 120%;
  font-size: 1em;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth !important;
}

.vh-100 {
  height: 100vh;
}

.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  font-size: 8px;
  padding: 2px 5px;
  color: #0000008a;
  border: solid 1px #0000008a;
  border-radius: 50%;
}

.d-flex {
  display: flex;
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

button:focus {
  outline: none;
}

.mat-dialog-container {
  scrollbar-width: thin;
  scrollbar-color: #aaa #ccc;
  height: 50vh;
  overflow: auto;
}

/* Works on Chrome, Edge, and Safari */
.mat-dialog-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.mat-dialog-container::-webkit-scrollbar-track {
  background: #ccc;
}

.mat-dialog-container::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.no-wrap {
  white-space: nowrap;
}

/* CABEÇALHO DE TODAS AS LISTAS*/
.cabecalho {
  margin-top: 1rem;
}

.h1 {
  width: auto;
  font-size: 2rem;
  padding-right: 0;
  margin-bottom: 0;
}

.linha {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.linha-fim {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

#visibilidade {
  margin-top: 0.3rem;
  margin-right: 5rem;
  position: relative;
  display: flex;
}

.filtro-listas {
  border: 1px solid var(--azul-hidrometer);
  border-radius: 18px;
  color: var(--azul-hidrometer);
  background-color: #fff !important;
  padding: 0 0.2rem;
  height: 1.5rem !important;
  cursor: pointer;
}

.filtro-listas:focus {
  outline: none;
}

.info-listas {
  color: #aaa;
}

.input-pesquisa {
  margin-top: 1rem !important;
  margin-bottom: 0.5rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #aaa !important;
}

.input-pesquisa:focus {
  border-bottom: 1px solid #41b6e6;
  outline: none;
}

.dropdown {
  width: 1rem;
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-bottom: 1rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 8px;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px #c2c2c2;
  padding: 6px 8px;
  z-index: 1000;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.info-icone {
  color: #41b6e6;
  margin-left: 0.2rem;
}

/* CABEÇALHO DE TODAS AS LISTAS*/

/*BOTOES*/
.botao-criar {
  background-color: var(--azul-hidrometer) !important;
  color: #fff;
  border-radius: 20px !important;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid var(--azul-hidrometer) !important;
  padding: 5px 17px;
  margin: 0 5px;
}

.botao-criar:not([disabled]):hover {
  background-color: #fff !important;
  color: var(--azul-hidrometer);
  border-radius: 20px;
  cursor: pointer;
  vertical-align: middle;
}

.botao-editar {
  background-color: #fff !important;
  color: var(--azul-hidrometer);
  border-radius: 20px !important;
  cursor: pointer;
  vertical-align: middle;
  margin: 0 5px;
  border: 1px solid var(--azul-hidrometer) !important;
  padding: 5px 17px;
}

.botao-editar:hover {
  background-color: var(--azul-hidrometer) !important;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  vertical-align: middle;
}

.botao-excluir {
  background-color: #fff !important;
  color: #df4747;
  border-radius: 20px !important;
  cursor: pointer;
  vertical-align: middle;
  margin: 0 5px;
  border: 1px solid #df4747 !important;
  padding: 5px 17px;
}

.botao-excluir:hover {
  background-color: #df4747 !important;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  vertical-align: middle;
}

.botao-icone-mensagem {
  background-color: #b0e5fb;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  color: #41b6e6;
  border-radius: 9px !important;
  padding: 5px;
}

.botao-aviso {
  background-color: #fff !important;
  color: #df9d47;
  border-radius: 20px !important;
  cursor: pointer;
  vertical-align: middle;
  margin: 0 5px;
  border: 1px solid #df9d47 !important;
  padding: 5px 17px;
}

.botao-aviso:hover {
  background-color: #df9d47 !important;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  vertical-align: middle;
}

.form-row_botoes {
  margin-bottom: 1.2rem;
}

/*BOTOES*/

/*TABELA*/

.mat-header-cell {
  font-size: 1em;
  color: #41b6e6;
}

.mat-cell,
.mat-header-cell {
  padding: 0 0.2rem !important;
}

.mat-header-row,
.mat-row {
  height: 1em;
}

.tabela-graficos {
  overflow: auto;
  height: 100% !important;
  max-height: 100% !important;
}

/*TABELA*/

/*PAGINACAO*/
pagination-controls {
  position: fixed;
  bottom: 0;
  right: 5vw;
}

mat-paginator {
  font-size: 1em;
  margin-top: 2vh;
  background-color: inherit;
}

/*PAGINACAO*/

/*EDITAR*/
.editar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.editar form {
  margin-top: 1rem;
}

mat-form-field {
  width: 30vw;
}

/*EDITAR*/

/*LISTA*/
.lista-sem-bolinha {
  display: inline;
  justify-content: start;
  align-items: start;
  list-style: none;
  line-height: 120%;
  font-size: 1em;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.lista-sem-bolinha li {
  box-sizing: border-box;
  padding-top: 0.5vh;
  padding-bottom: 0.125vh;
}

/*LISTA*/

.excluido {
  color: #df4747;
  text-decoration: line-through;
}

.input-padrao {
  border-radius: 18px !important;
  min-width: 1rem !important;
  padding: 0.1rem !important;
  color: var(--azul-hidrometer) !important;
  border: 1px solid var(--azul-hidrometer) !important;
  background-color: #fff !important;
  padding: 2px 10px !important;
}

.tabela-graficos-pizza {
  height: 10%;
  position: relative;
  right: 4rem;
  overflow: auto;
}

.linha-grafico-tabela-legenda {
  padding: 0 calc(2px + 1.5625vw);
  height: 69%;
  max-height: 82%;
  place-content: center space-between;
}

.linha-grafico-tabela-legenda:after {
  content: "";
  display: table;
  clear: both;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

/* #009DE3*/

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}

.carousel-indicators .active {
  background-color: #00a0e4 !important;
}

.carousel-indicators li {
  background-color: #00a0e48f !important;
}

.carousel-indicators {
  margin-right: 0 !important;
}

.carousel-inner {
  height: 100%;
  width: 100%;
}

.carousel-inner .sr-only {
  display: none;
  height: 100%;
}

.carousel-item.active {
  height: 100%;
}

.margem-clr-icon {
  margin-right: 0.3rem;
}

.centralizar {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.mat-menu-panel {
  overflow: visible !important;
}

.tamanho-tabela-graficos {
  max-height: 15px;
}

.overflow {
  overflow: auto;
  margin-left: 20px;
  margin-right: 10px;
  padding-right: 10px;
  height: 100% !important;
}

.overflow::-webkit-scrollbar {
  width: 5px;
}

.overflow::-webkit-scrollbar-track {
  background: #ebecf0;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 3px solid #9b9ca0;
}

.no-padding-dialog .mat-dialog-container {
  padding: 0 !important;
}

/* dialog */
.dialog-titulo {
  color: #414141;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dialog-subtitulo {
  color: #949494;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Novos campos com imagem/ícone a esquerda */
.left-inner-addon {
  position: relative;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  color: var(--azul-hidrometer);
}

.left-inner-addon select,
.left-inner-addon input,
.left-inner-addon mat-date-range-input {
  display: flex;
  padding-left: 12% !important;
  width: 100%;
  font-size: 0.85vw;
}

.left-inner-addon img,
.left-inner-addon svg {
  position: absolute;
  pointer-events: none;
  padding-left: 3%;
  width: 12%;
  height: 100%;
  max-width: 1.5rem;
}

.left-inner-addon * {
  cursor: pointer;
}

.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden;
}

.radio-visibilidade {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 2.5px;
}